.container {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  width: 99vw;
  height: 15vh;
  padding-top: 1vh;
}

.text {
  display: flex;
  align-self: center;
  align-content: center;
  font-family: Baskerville;
  font-size: 48px;
  font-weight: bold;
  line-height: 55px;
  color: #000000;
  padding-left: 3%;
}
.iframe {
  width: 90vw;
  height: 65vh;
  display: flex;
  align-content: center;
  align-self: center;
  margin-bottom: 5vh;
}
@media only screen and (max-width: 720px) {
  .header {
    height: 10vh;
    padding-top: 2vh;
  }
  .text {
    font-size: 30px;
  }
  .line {
    width: 40px;
  }
  .iframe {
    height: 63vh;
    margin-bottom: 20vh;
  }
}
@media only screen and (max-width: 340px) {
  .text {
    font-size: 28px;
  }
}
