.container {
  display: flex;
  position: fixed;
  width: 99vw;
  justify-content: space-between;
  align-content: center;
  background-color: white;
  height: 8vh;
  margin-top: -1px;
}
.containerOpen {
  display: flex;
  height: 8vh;
}
.link {
  display: flex;
  flex: 1;
  font-weight: normal;
  text-decoration: none;
  color: #000000;
  font-weight: lighter;
  font-style: normal;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 22px;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}
.logo {
  composes: link;
  text-align: left;
  padding: 8px 0 0 5vw;
}
.investNow {
  composes: link;
  background: #c1c17d;
  color: white;
}
.menuItemsContainer {
  margin-top: 10%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 41.5vh;
}
.menuButton {
  border: none;
  background: none;
  padding-right: 5vw;
}
@media only screen and (max-width: 340px) {
  .menuItemsContainer {
    height: 30vh;
    padding-bottom: 56.5vh;
  }
}
