.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header {
  display: flex;
  width: 100vw;
  height: 15vh;
  padding-top: 1vh;
  align-items: center;
  justify-items: stretch;
}

.text {
  display: flex;
  align-self: center;
  align-content: center;
  font-family: 'Baskerville';
  font-size: 48px;
  font-weight: bold;
  line-height: 55px;
  color: #000000;
  padding-left: 3%;
  width: 60vw;
}
.subtitle {
  font-family: 'Montserrat' 'sans-serif';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding-left: 12vw;
}
.iframe {
  width: 90vw;
  height: 65vh;
  display: flex;
  align-content: center;
  align-self: center;
  margin-bottom: 5vh;
}
.hide {
  display: none;
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
  padding-top: 10vh;
}
.optionButton {
  background: #8a8d09;
  border-radius: 30px;
  color: white;
  width: 24vw;
  height: 7.5vh;
  border: none;
  font-family: 'Montserrat' 'sans-serif';
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.goBackButton {
  background: none;
  display: flex;
  align-items: center;
  justify-items: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  width: 188px;
  height: 50px;
  text-decoration: none;
  justify-content: space-around;
  padding: 0px 11px 0px 11px;
  color: #33737c;
  border: 2px solid;
}
@media only screen and (max-width: 720px) {
  .header {
    height: 10vh;
    padding-top: 2vh;
  }
  .text {
    font-size: 28px;
  }
  .line {
    width: 36px;
  }
  .iframe {
    height: 70vh;
    margin-bottom: 12vh;
  }
  .subtitle {
    padding-left: 10vh;
  }
  .optionButton {
    width: 42vw;
    font-size: 12px;
  }
  .goBackButton {
    width: 120px;
    height: 38px;
    margin-left: 8vw;
    margin-right: 6vw;
    font-size: 11px;
  }
}
@media only screen and (max-width: 340px) {
  .goBackButton {
    margin-left: 8vw;
    width: 100px;
    height: 36px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 867px) {
  .text {
    font-size: 56px;
  }
  .subtitle {
    padding-left: 6.9vw;
    padding-bottom: 2vh;
    font-size: 24px;
  }
  .optionButton {
    width: 16vw;
  }
  .goBackButton {
    margin-left: 20vw;
    width: 320px;
    height: 70px;
    font-size: 16px;
    margin-right: 10vw;
  }
}
