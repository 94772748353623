.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100vh;
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  display: none;
}
.rootContainer {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}
.rootContainer::-webkit-scrollbar {
  display: none;
}
.InvestNowClass {
  composes: container;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: auto;
}
.VideoLibraryClass {
  composes: container;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: auto;
}

.HowToInvestClass {
  composes: container;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: auto;
}
.ContactUsClass {
  composes: container;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: auto;
}
.InvestorPresentationClass {
  composes: container;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: auto;
}

.openedMenu {
  display: none;
}
.closedMenu {
  display: block;
  padding-top: 7vh;
}

::-webkit-scrollbar {
  width: 0px;
}
