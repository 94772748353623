.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.arrowsAndMainVideo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 3%;
  padding-top: 5%;
}
.playingVideo {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
}
.mainIframe {
  border-radius: 3%;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-self: stretch;
  justify-items: stretch;
  width: 650px;
  height: 380px;
  margin-top: 3%;
}
.arrow {
  display: flex;
  max-height: 10vh;
  background: none;
  border: none;
  align-self: center;
  justify-items: center;
  padding-left: 17px;
}
.leftArrow {
  composes: arrow;
  padding-right: 18px;
}
.rightArrow {
  composes: arrow;
}
.arrowImage {
  display: flex;
}
.middleLineAndText {
  display: flex;
  width: 90vw;
  height: 7vh;
  justify-self: flex-start;
  flex-direction: row;
  justify-content: baseline;
  align-content: center;
  vertical-align: middle;
  padding-top: 5vh;
  padding-bottom: 3vh;
}

.videoLibraryText {
  display: flex;
  align-self: center;
  align-content: center;
  font-family: Baskerville;
  font-size: 36px;
  line-height: 48px;
  color: #000000;
  padding-left: 4%;
}
.amountOfVideos {
  display: flex;
  align-self: center;
  align-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 2%;
  padding-top: 1%;
  color: #000000;
}
.otherVideosContainer {
  display: flex;
  flex-wrap: wrap;
}
.otherVideoContainer {
  display: flex;
  position: relative;
  color: #000000;
  flex-direction: column;
  width: 280px;
  align-items: center;
  align-content: center;
  padding-bottom: 10px;
  padding-top: 2vh;
}
.otherVideoButton {
  display: flex;
  position: relative;
  border: none;
  background: none;
  width: 220px;
  justify-content: center;
  justify-items: center;
  margin-left: -10px;
  z-index: 200;
}
.otherVideoIframe {
  position: relative;
  width: 210px;
  height: 120px;
  background: none;
  border: none;
  border-radius: 8%;
  z-index: 1;
}

.header {
  width: 210px;
  font-family: Roboto;
  font-style: normal;
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  text-align: match-parent;
  font-weight: bold;
  padding: 1vh 0 1vh 0;
}
.description {
  width: 210px;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  font-weight: lighter;
  text-align: match-parent;
  color: #000000;
  padding-top: 5px;
  line-height: 16px;
  padding-bottom: 3vh;
}

.overlay {
  height: 100%;
  width: 100%;
  z-index: 200;
  position: absolute;
  background-color: transparent;
}
@media only screen and (min-width: 867px) {
  .mainIframe {
    margin-top: 1%;
    width: 1000px;
    height: 450px;
  }
  .videoLibraryText {
    font-size: 50px;
  }
  .amountOfVideos {
    font-size: 25px;
  }
  .otherVideosContainer {
    padding-left: 150px;
  }
  .otherVideoContainer {
    width: 400px;
  }
  .otherVideoButton {
    width: 400px;
  }
  .otherVideoIframe {
    width: 400px;
    height: 200px;
    border-radius: 5%;
  }
  .header {
    width: 300px;
    font-size: 20px;
    line-height: 20px;

    margin-left: -30px;
  }
  .description {
    width: 300px;
    font-size: 16px;
    line-height: 18px;
    margin-left: -30px;
  }
  .middleLineAndText {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
