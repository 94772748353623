* {
  margin: 0;
  padding: 0;
}
.body {
  color: black;
  height: 1500px;
  margin: 10% 7% 0px 10%;
  font-family: 'Montserrat' 'sans-serif';
}

.line {
  position: absolute;
  margin-top: 35px;
  margin-left: -10%;
}

.howToTitle {
  font-family: Baskerville;
  font-size: 44px;
  font-weight: 600;
}

.subtitle {
  font-weight: 100;
  font-size: 24px;
  margin-bottom: 65px;
}
.stepContainer {
  display: flex;
}

.step {
  margin-bottom: 40px;
}
.numStep {
  font-size: 36px;
  color: #8a8d09;
  margin-right: 7px;
}

.stepTitle {
  font-size: 22px;
  align-self: center;
  max-width: 610px;
}
.stepTitleNotSelected {
  font-size: 22px;
  font-weight: lighter;
  align-self: center;
  max-width: 588px;
}

.toogleTextButton {
  border-radius: 50%;
  margin-left: 10px;
  height: 50px;
  width: 50px;
  background: transparent;
  border: 2px solid #000000;
}
.vector {
  min-width: 10px;
  min-height: 10px;
}
.hide {
  display: none;
}
.textStep {
  font-size: 18px;
  font-weight: lighter;
}
.textParagraph {
  composes: textStep;
  margin: 20px 0 0 30px;
}
.indentParagraph {
  composes: textStep;
  margin: 20px 0 0 45px;
}
.indentParagraphStep3 {
  composes: textStep;
  margin: 20px 0 0 45px;
  font-weight: 600;
}
.indentSpan {
  composes: textStep;
  margin: 20px 0 0 80px;
}
.indentLevelThree {
  composes: textStep;
  margin: 20px 0 0 30px;
}
.link {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10% 0 0;
}
a {
  text-decoration: none;
}
.startInvestmentButton {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  justify-items: center;
  font-weight: 600;
  color: white;
  border-radius: 30px;
  width: 182px;
  height: 54px;
  border: 1px solid #8a8d09;
  background-color: #8a8d09;
  text-decoration: none;
  font-family: 'Montserrat' 'sans-serif';
}

button:hover {
  cursor: pointer;
}

.buttonText {
  font-size: 14px;
  padding-left: 40px;
}

@media only screen and (max-width: 720px) {
  .body {
    margin: 10% 5% 10% 10%;
    height: 2000px;
  }
  .line {
    display: none;
  }
  .howToTitle {
    font-size: 30px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 22x;
    margin-bottom: 40px;
  }
  .numStep {
    float: left;
    font-size: 26px;
    margin-right: 9px;
  }
  .step {
    margin-bottom: 50px;
  }
  .stepContainer {
    align-items: center;
  }
  .stepTitle {
    font-size: 20px;
    width: 220px;
  }
  .stepTitleNotSelected {
    width: 220px;
    font-size: 20px;
  }
  .textParagraph {
    margin: 30px 22px 0 20px;
    font-size: 19px;
  }
  .indentParagraph {
    margin: 30px 22px 0 28px;
    font-size: 19px;
  }
  .toogleTextButton {
    grid-column-start: 3;
    float: right;
  }
  .indentSpan {
    margin: 20px 0 0 35px;
  }
  .indentLevelThree {
    margin: 20px 0 0 10px;
  }
  .link {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
  }
}
