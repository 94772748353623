* {
  margin: 0;
  padding: 0;
}
.options {
  display: block;
}
.body {
  margin: 10% 10% 0% 10%;
  font-family: 'Montserrat', 'sans-serif';
  min-height: 100vh;
  grid-template-rows: 25% 40% 20%;
}

.line {
  position: absolute;
  margin-top: 35px;
  margin-left: -10%;
}
.title {
  padding-left: 0%;
  padding-bottom: 2%;
  grid-column-start: 1;
}
.title h1 {
  font-family: Baskerville;
  font-weight: 600;
  font-size: 44px;
  padding-bottom: 0.5%;
}
.subtitle {
  font-weight: 100;
  font-size: 24px;
  margin-bottom: 65px;
  font-family: 'Montserrat', 'sans-serif';
}

.radioButtonContainer {
  height: 300px;
}

.questionContainer {
  display: flex;
  align-items: center;
}

.numQuestion {
  font-size: 36px;
  color: #8a8d09;
}

.question {
  font-size: 24px;
}

.containerRadio {
  width: 200px;
  padding: 18px 0px 40px 10px;
}
.radio {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 12% 0px 8% 0px;
  width: 200px;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  height: 42px;
  width: 172px;
}

.radio button {
  font-size: 18px;
  padding: 0px 10px 0px 10px;
  border-radius: 30px;
  text-decoration: none;
  position: relative;
  margin-bottom: 4px;
  margin-left: 3%;
  height: 48px;
  width: 200px;
  text-align: left;
  border: 1px solid #33737c;
  display: flex;
  align-items: center;
}
.radio strong {
  font-size: 24px;
  font-weight: 600;
}
.buttonText {
  padding-left: 10px;
}

.notChecked {
  color: #33737c;
  background: white;
}

.checked {
  color: white;
  background-color: #33737c;
}
.startInvestmentContainer {
  margin-bottom: 10%;
  margin-right: 10px;
}

.buttonsContainer {
  grid-row-start: 3;
  display: flex;
  justify-content: flex-end;
}

.startInvestmentButton {
  padding: 0px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border-radius: 30px;
  width: 188px;
  height: 50px;
  text-decoration: none;
  justify-content: space-around;
  padding: 0px 11px 0px 11px;
}

.docusignLink {
  text-decoration: none;
}
.startInvestmentButtonEnabled {
  composes: startInvestmentButton;
  border: 1px solid #8a8d09;
  background-color: #8a8d09;
}
.goBackButton {
  composes: startInvestmentButton;
  color: #33737c;
  background-color: transparent;
  border: 2px solid;
}
.goBackButtonDisabled {
  display: none;
}

button:hover {
  cursor: pointer;
}

.vector {
  margin-left: 10px;
}

.notEnabled {
  composes: startInvestmentButton;
  background-color: black;
  opacity: 0.3;
}

.docusign {
  margin-top: 4%;
  width: 100%;
  height: 700px;
  margin-bottom: 15px;
}
.mobileButtonsContainer {
  display: none;
}

@media only screen and (min-width: 867px) {
  .title h1 {
    font-size: 60px;
  }
  .title h3 {
    font-size: 32px;
  }
  .docusign {
    height: 1000px;
  }
  .radio button {
    font-size: 20px;
  }
  .radio strong {
    font-size: 24px;
  }

  .numQuestion {
    font-size: 32px;
    color: #8a8d09;
  }

  .question {
    font-size: 26px;
  }
  .radio button {
    font-size: 20px;
    padding: 0px 10px 0px 15px;
    margin-bottom: 4px;
    margin-left: 3%;
    height: 58px;
    width: 300px;
  }
  .startInvestmentButton {
    font-size: 16px;
    width: 250px;
    height: 60px;
    padding: 0px 11px 0px 11px;
  }
}

@media only screen and (max-width: 720px) {
  .body {
    margin: 10% 5% 10% 10%;
  }
  .title h1 {
    font-size: 30px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .line {
    display: none;
  }
  .docusign {
    height: 650px;
  }
  .questionContainer {
    display: flex;
    align-items: center;
  }
  .radio strong {
    font-size: 20px;
  }
  .buttonsContainer {
    display: none;
  }
  .mobileButtonsContainer {
    grid-row-start: 3;
    display: flex;
    justify-content: space-around;
  }
  .mobileStartInvestmentButton {
    padding: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    color: white;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: black;
    text-decoration: none;
  }
  .mobileNotEnabled {
    composes: mobileStartInvestmentButton;
    opacity: 0.2;
  }
  .mobileBackNotEnabled {
    display: none;
  }
  .mobileStartEnabled {
    composes: mobileStartInvestmentButton;
    border: 1px solid #c1c17d;
    background-color: #c1c17d;
  }
  .mobileEnabled {
    composes: mobileStartInvestmentButton;
    border: 2px solid #33737c;
    background-color: transparent;
  }
  .backVector {
    padding: 10px;
  }
  .vector {
    padding: 10px;
    margin: 0px;
  }
  .radio button {
    font-size: 14px;
  }

  .numQuestion {
    font-size: 26px;
    color: #8a8d09;
  }

  .question {
    font-size: 16px;
  }
}
