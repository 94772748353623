.middleLineAndText {
  display: flex;
  padding: 20vh 0 20vh 0;
  width: 95vw;
  height: 5vh;
  justify-content: flex-start;
  flex-direction: row;
  align-content: center;
  vertical-align: middle;
  padding-top: 8vh;
  padding-bottom: 2vh;
}
.videoLibraryText {
  display: flex;
  align-self: center;
  align-content: center;
  font-family: Baskerville;
  font-size: 28px;
  line-height: 48px;
  color: #000000;
  padding-left: 3%;
}
.amountOfVideos {
  display: flex;
  align-self: center;
  align-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  padding-left: 2%;
  padding-top: 1%;
  color: 000000;
}
.allVideosContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.header {
  margin-top: -1.5vh;
  width: 85vw;
  padding-bottom: 2vh;
  font-size: 24px;
  line-height: 20px;
  color: 000000;
  align-self: center;
}
.description {
  font-size: 15px;
  line-height: 18px;
  width: 85vw;
  color: #000000;
  padding-bottom: 6vh;
}
.iframeContainer {
  justify-content: stretch;
  align-content: stretch;
}
.iframe {
  border-radius: 12%;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-self: stretch;
  justify-items: stretch;
  width: 85vw;
  height: 250px;
  margin-top: 4vh;
}
