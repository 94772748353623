.container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  justify-items: center;
  background: white;
  position: fixed;
  width: 100vw;
  z-index: 300;
  border-bottom: 0.5px solid #a1a2a6;
}
.link {
  display: flex;
  flex: 1;
  justify-content: center;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  color: #363535;
  font-weight: normal;
  align-items: center;
  align-self: center;
  font-size: 11px;
  padding: 1.2% 0 1.2% 0;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
}
.linkSelected {
  composes: link;
  font-weight: bold;
  background: #e6e6e6;
  border-bottom: 0.5px solid #a1a2a6;
}
.investorPresentation {
  composes: link;
  flex: 1.4;
}
.investorPresentationSelected {
  composes: linkSelected;
  flex: 1.4;
  border-bottom: 0.5px solid #a1a2a6;
}
.hideBar {
  display: none;
}

.logo {
  display: flex;
  padding-left: 10px;
  composes: link;
}
.investNow {
  composes: link;
  flex: 1;
  background: #8a8d09;
  align-self: stretch;
  align-items: center;
  color: #ffffff;
}
.investNow:hover {
  display: flex;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  align-self: stretch;
  align-items: center;
  background: #33737c;
}
.investNowClicked {
  composes: link;
  align-self: stretch;
  align-items: center;
  background: #33737c;
  color: #ffffff;
  font-weight: bold;
}
@media only screen and (min-width: 867px) {
  .link {
    font-size: 16px;
  }
}
